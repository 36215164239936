.section-myuniaml {
  .slider-body {
    border-radius: 10px;

    .set-def-btn {
      display: inline-block;
      padding: 10px 30px;
      border-radius: 40px;
      outline: none;
      border: none;
    }

    .slider-left-content {
      border-bottom: solid 1px rgba(255, 255, 255, 0.1);
    }

    .slider-right-content {
      border-bottom: solid 1px rgba(255, 255, 255, 0.1);

      &:last-child {
        border-bottom: none;
      }
    }

    /* Slider Design Start */
    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 5px;
      border-radius: 5px;
      background: #4d84e8;
      outline: none;
      opacity: 0.8;
      -webkit-transition: .2s;
      transition: opacity .2s;
    }

    .slider:hover {
      opacity: 1;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #fd9620;
      cursor: pointer;
    }

    .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #4CAF50;
      cursor: pointer;
    }

    /* Slider Design End */
  }
}
