/*Style For Header*/

.section-header {
  position: relative;

  .navigation-bar {
    @media screen and (max-width: 991px) {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #0e2063;
      z-index: 1;
    }

    .new-srch-btn {
      padding: 10px 30px;
      border-radius: 40px;
      background-color: #2367c8;
      border: 1px solid #2367c8;
      outline: none;
      @media screen and (max-width: 374px) {
        padding: 10px;
      }
    }

    ul {
      li {
        margin: 0 5px;

        a, button {
          display: inline-block;
          border-radius: 40px;
          border: 1px solid #fff;
          color: #ffffff;
          padding: 10px 30px !important;
          font-size: 14px;
          font-family: Rubik-medium;

          &:hover {
            background: #fd9620;
            border: 1px solid #fd9620;
          }

          @media screen and (width: 1024px) {
            padding: 10px 20px !important;
          }

        }

        button {
          background-color: transparent;
          outline: none;
        }
      }

      li:nth-child(2) {
        .btn-sign {
          padding: 10px 70px !important;

          &:hover {
            background: #fd9620;
            border: none;
          }
        }

        a {
          display: inline-block;
          border: none;
          color: #fff;
          padding: 0 !important;
          font-size: 14px;
          font-family: Rubik-medium;

          &:hover {
            background: transparent;
            border: none;
          }
        }
      }

      .padding-0 {
        a {
          padding: 0 !important;
        }
      }

      .mon-btn {
        display: inline-block;
        border-radius: 40px !important;
        border: 1px solid #fff !important;
        color: #ffffff;
        padding: 10px 30px !important;
        font-size: 14px;
        font-family: Rubik-medium;

        &:hover {
          background: #fd9620 !important;
          border: 1px solid #fd9620 !important;
        }
      }
    }

    .togg-btn {
      outline: none;
    }

    .signBtn {
      padding: 10px 70px !important;
    }
  }


}

.coin-select {
  color: #ffffff;

  .css-1bl8mcx-Control, .css-1domaf0 {
    padding: 3px;
    display: flex;
    background: #fd9620;
    border-radius: 40px;
    width: 150px;
    @media screen and (width: 1024px) {
      width: 120px;
    }
  }

  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  .css-1okebmr-indicatorSeparator {
    background-color: #fff;
  }
}

/*Switch On/Off*/

.switch-server {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 12px 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  .round {
    border-radius: 34px;
  }

  .round:before {
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #fd9620;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #fd9620;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

@media screen and (max-width: 1024px) {
  .banr-res-btn {
    margin: 0 !important;

    button {
      margin: 0 !important;
    }
  }
}


