/*Style for coin details*/
.section-coin-details {
  .tabination {
    ul {
      li {
        border: none;
        margin: 0 10px;
        border-radius: 0;

        button {
          color: #ffffff;
          border: 2px solid #ffffff;
          padding: 10px 40px;
          border-radius: 40px;
          outline: none;
          background: transparent;
        }
      }

      li:first-child {
        margin-left: 0;
      }

      .active {
        border-radius: 40px;
        border: 2px solid #2367c8;
        background: #2367c8;
        color: #ffffff;
      }
    }

    .tabs {
      overflow-x: scroll;
      display: block;
      white-space: nowrap;

      li, a {
        display: inline-block;
      }
    }

    /*Scoring Tab Start*/
    .scoring-collapse {
      .card {
        background-color: #1c2253;
        border-radius: 10px;
      }

      .card-header {
        height: 80px;

        .scor-btn-coll {
          font-family: Rubik-medium;
          background: #fd9620;
          border: 1px solid #fd9620;
          padding: 5px 20px;
          border-radius: 40px;
          white-space: nowrap;
          outline: none;
        }
      }

      .card-body {
        background-color: #171d4f;
        border-top: 1px solid #333a63;

        li {
          font-size: 14px;
          color: #ff5023;
          margin-left: 0;
          margin-bottom: 10px;
        }

        .identify-btns {
          background-color: #1c2253;
          border-top: 1px solid #333a63;

          .dwn-report {
            padding: 10px 30px;
            border-radius: 20px;
            background: #fd9620;
            border: none;
            outline: none;
          }

          .reg-hash {
            padding: 10px 30px;
            border-radius: 20px;
            background: #2367c8;
            border: none;
            outline: none;
          }
        }
      }
    }

    /*Scoring Tab End*/

    /*Summary Tab Start*/
    .summary-main {
      .card {
        border-radius: 10px;

        h5 {
          font-family: Rubik-medium;
        }

        .law-btn {
          border: 1px solid #3cc24c;
          background-color: #3cc24c;
          padding: 8px 30px;
          border-radius: 40px;
          display: inline-block;
        }
      }

      .summary-left {
        li {
          margin-left: 0;
          margin-right: 0;
          white-space: nowrap;
        }
      }
    }

    /*Summary Tab End*/

    /*Balance Tab Start*/
    .balance-main {
      .card {
        border-radius: 10px;

        h5 {
          font-family: Rubik-medium;
        }
      }
    }

    /*Balance Tab End*/

    /*Address Tab Start*/
    .address-main {
      .hop-select {
        color: #fff;

        .css-1bl8mcx-Control, .css-1domaf0 {
          padding: 10px 30px;
          display: flex;
          background: #fd9620;
          border-radius: 40px;
        }

        .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
          color: #fff;

          &:hover {
            color: #fff;
          }
        }

        .css-1okebmr-indicatorSeparator {
          background-color: #fff;
        }
      }

      .address-table {
        ::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #384378;
          border-radius: 10px;
        }

        .scor-btn-coll {
          background: #fd9620;
          border: 1px solid #fd9620;
          padding: 4px 18px;
          border-radius: 40px;
          white-space: nowrap;
          outline: none;
        }

        .scor-btn-coll-disable {
          background: #fd9620;
          border: 1px solid #fd9620;
          padding: 4px 18px;
          border-radius: 40px;
          white-space: nowrap;
          outline: none;
          cursor: not-allowed;
        }

        table {
          width: 100%;
          border-collapse: separate;
          border-radius: 1em;
          overflow: hidden;
          border-spacing: 0 15px;
        }

        tr {
          padding: 1em;
          background: #1c2253;
        }

        thead {
          tr {
            background: #384378;
          }
        }

        td {
          vertical-align: middle;
          padding: .7em;
          border: transparent;
          white-space: nowrap;
          min-width: 180px;
        }

        td:first-child {
          border-radius: 10px 0 0 10px;
        }

        td:last-child {
          border-radius: 0 10px 10px 0;
        }

        tbody {
          td {
            text-align: center;
          }
        }
      }

      .add-ele-btn {
        padding: 12px 35px;
        border-radius: 40px;
        outline: none;
        border: none;
      }

    }

    /*Address Tab End*/

    /*Web-Analysis Tab Start*/
    .web-analysis-table {
      table {
        width: 100%;
        border-collapse: separate;
        border-radius: 1em;
        overflow: hidden;
        border-spacing: 0 15px;
      }

      tr {
        padding: 1.5em;
        background: #1c2253;
        border-radius: 1em 0 0 1em;
      }

      thead {
        tr {
          background: #384378;
          border-radius: 1em 0 0 1em;

          td {
            font-family: Rubik-medium;
            font-size: 16px;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 14px;
          }
        }
      }

      td {
        vertical-align: middle;
        padding: 1.5em;
        border: transparent;
      }

      td:first-child {
        border-radius: 1em 0 0 1em;
      }

      td:last-child {
        border-radius: 0 1em 1em 0;
      }
    }

    /*Web-Analysis Tab End*/

    /*Transaction tab start*/
    .transaction {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding: 0 20px;

      svg {
        width: 100% !important;
        height: 100vh !important;
      }
    }
    /*Transaction tab end*/
  }
}

.arrow-img {
  width: 40px;
}

.json-viewer-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper-modal {
  background-color: white;
  border: 2px solid #000;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  padding: 20px 50px;
  width: calc(100% - 100px);
}

.iframe-big-device {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 20px;
  height: 100vh;
  border: none;
}

.credit-table {
  max-height: 500px;
  overflow-y: scroll;

  table {
    width: 100%;
    border-collapse: separate;
    border-radius: 1em;
    overflow: hidden;
    border-spacing: 0 15px;
  }

  tr {
    padding: 1.5em;
    background: #1c2253;
    border-radius: 1em 0 0 1em;
  }

  thead {
    tr {
      background: #1c2253;
      border-radius: 1em 0 0 1em;

      td {
        font-family: Rubik-medium;
        font-size: 16px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 14px;
      }
    }
  }

  td {
    vertical-align: middle;
    padding: 1.5em;
    border: transparent;
  }

  td:first-child {
    border-radius: 1em 0 0 1em;
  }

  td:last-child {
    border-radius: 0 1em 1em 0;
  }
}

.credit-table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.credit-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.credit-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/*Coin Template CSS Start*/
.coinTemplate {
  background: #FFFFFF;

  h6 {
    margin: 0;
    color: #000000;
  }

  p {
    margin: 0;
    font-size: 12px;
    color: #000000;
  }

  li {
    display: inline-block;
    margin: 0 50px;
  }

  .templateKey {
    background-color: #fd9620;
    border-radius: 40px;
    color: #FFFFFF;

  }
}

/*Coin Template CSS End*/
