/*App Main Style (Global) define here*/

body, html {
  font-family: Rubik-regular;
  width: 100%;
  height: 100%;
  background-image: url("../img/banner-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow-y: scroll;
}

* {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 5px;
}

.tabs::-webkit-scrollbar {
  width: 5px;
}

.address-table::-webkit-scrollbar {
  width: 5px;
}

.tabs::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.address-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.tabs::-webkit-scrollbar-thumb {
  background: #384378;
  border-radius: 10px;
}

.address-table::-webkit-scrollbar-thumb {
  background: #384378;
  border-radius: 10px;
}

/* Handle on hover */
.tabs::-webkit-scrollbar-thumb:hover {
  background: #384378;
}

.address-table::-webkit-scrollbar-thumb:hover {
  background: #384378;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
}

.f-16 {
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}

.f-18 {
  font-size: 18px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.f-20 {
  font-size: 20px;
  @media screen and (max-width: 475px) {
    font-size: 14px;
  }
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-30 {
  font-size: 30px;
  @media screen and (max-width: 991px) {
    font-size: 20px;
  }
}

.w-40 {
  width: 49% !important;
}

.h-58 {
  height: 58px;
}

.pr-50 {
  padding-right: 50px !important;
}

.ver-midd {
  vertical-align: middle !important;
}

.f-rub-bol {
  font-family: Rubik-bold;
}

.f-rub-med {
  font-family: Rubik-medium;
}

.border-100 {
  border-radius: 100%;
}

.word-break {
  word-break: break-all;
}

.over-hidden {
  @media screen and (max-width: 425px) {
    overflow: hidden;
  }
}

.text-green {
  color: #29b05c;
  font-family: Rubik-bold;
  white-space: nowrap;
}

.text-orange {
  color: #fd9620;
  font-family: Rubik-bold;
  white-space: nowrap;
}

.text-red {
  color: #f84d4d;
  font-family: Rubik-bold;
  //white-space: nowrap;
  word-wrap: break-word;
}

.bg-dark-blue {
  background-color: #1c2253;
}

.bg-blue-card {
  background-color: #2367c8;
}

.bg-green {
  background-color: #3cc24c;
}

.bg-card-white {
  background-color: #f5f5f5;
}

.text-orange {
  color: #fd9620;
}

.bg-orange {
  background-color: #fd9620;
}

.bg-red {
  background-color: #dc3545;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.content {
  min-height: calc(100vh - 182px);
  @media screen and (max-width: 991px) {
    padding-top: 130px;
  }
}

// for loader
.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  opacity: 0.5;
  background-image: url("../img/banner-bg.png");
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.comn-button {
  font-family: 'Rubik-medium';
  background: #fd9620;
  border: 1px solid #fd9620;
  padding: 10px 20px;
  border-radius: 40px;
  white-space: nowrap;
  outline: none !important;
}

/*Style For Open Modal start*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

/*Style For Open Modal Close */

/* Style for White space start*/
.white-space-wrap {
  white-space: nowrap;
}

.white-space-normal {
  white-space: normal;
}

/* Style for White space end*/

// Border None
.border-none {
  border: none;
}

//outline none
.outline-none {
  outline: none !important;
}

// pagination - center

.center-pagination {
  ul {
    justify-content: center !important;
  }
}

input::placeholder {
  color: grey !important;
  font-size: 14px !important;
}


/*Checkbox Style Start*/
.check-main {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  word-break: break-all;
}

/* Hide the browser's default checkbox */
.check-main input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: solid 2px #3f4577;
  background-color: #1c2253;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-main input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-main .checkmark:after {
  left: 11px;
  top: 5px;
  width: 6px;
  height: 15px;
  border: solid #fd9620;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Checkbox Style End*/

// Common Table CSS
.user-table {
  table {
    width: 100%;
    border-collapse: separate;
    border-radius: 1em;
    overflow: hidden;
    border-spacing: 0 15px;
    vertical-align: top;
    align-items: start;
  }

  tr {
    padding: 1.5em;
    background: #1c2253;
    border-radius: 1em 0 0 1em;
  }

  thead {
    tr {
      background: #384378;
      border-radius: 1em 0 0 1em;
    }

    td {
      font-size: 16px;
      font-family: Rubik-medium;
      padding: 1.5em;
    }
  }

  tbody {
    td {
      word-break: break-all;
      vertical-align: top;
      align-items: start;
      padding: 15px 1.5em;
    }

    td:nth-child(2) {
      overflow-x: hidden;

      li {
        overflow: hidden;
        max-width: 250px;
        text-overflow: ellipsis;
      }
    }
  }

  td {
    font-size: 14px;
    white-space: nowrap;
    border: transparent;
  }

  td:first-child {
    border-radius: 1em 0 0 1em;
  }

  td:last-child {
    border-radius: 0 1em 1em 0;
  }

  .inner-table {
    td {
      border: 1px solid #384378;
    }
  }
}

/* width */
.user-table, .average-global-table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.user-table, .average-global-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.user-table, .average-global-table::-webkit-scrollbar-thumb {
  background: #384378;
  border-radius: 10px;
}

/*Table Fixed Head CSS*/
.table-fix-head {
  thead {
    display: block;
    overflow-y: auto;
  }

  tbody {
    display: block;
    max-height: 500px;
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #384378;
    border-radius: 10px;
  }
}

.get-credit-modal {
  a {
    font-family: 'Rubik-medium';
    background: #fd9620;
    border: 1px solid #fd9620;
    padding: 10px 20px;
    border-radius: 40px;
    white-space: nowrap;
    outline: none !important;
    color: white;
  }
  //span{
  //  align-self: center;
  //  margin-left: 10px;
  //}
}


