@font-face {
    font-family: 'Rubik-bold';
    src: url('Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-black';
    src: url('Rubik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-medium';
    src: url('Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-light';
    src: url('Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik-regular';
    src: url('Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

